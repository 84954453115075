@import "@/styles/_vars.scss";
@import '_fonts.scss';
@import '_vars.scss';
@import 'bootstrap-grid.min.css';
@import 'video-js.css';
@import 'videojs-city-theme.css';

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  overflow-x: hidden;
}

html{
  height:100%;
}

body{
  background-color:$yellow;
  margin:0;
  height:100vh;
  overflow:hidden;
}

#app{
  display:flex;
  height:100%;
  overflow-x: hidden;

  #left{
    position: relative;
    width:$side-width;
    height:auto;
    display: flex;
    flex-direction: column;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  
    box-sizing: border-box;
    overflow: auto;
    padding-top: 200px;
    scroll-behavior: smooth;

    @include breakpoint-portrait-850px {
      width: 100%;
      padding-top: 150px;
      overflow-x: hidden;
    }
    
    &::-webkit-scrollbar {
      display: none;
    }  

    .left-content-container {
      padding: 0 40px;
      flex-grow: 1;
      flex-shrink:0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: calc(100vh - 300px);

      @include breakpoint-portrait-850px {
        padding: 0 10px;
        min-height: none;
      }
    }

    .about-us-content {
      margin-bottom: 30px;
      margin-left: 16px;
      margin-right: 16px;

      .about-us-title {
        font-size: 14px;
        line-height: 23px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 17px;
      }

      .about-us-text {
          font-size: 20px;
          line-height: 24px;
      }
    }

/*     .content-page-search {
      @include breakpoint-portrait-850px {
        display: none;
      }
    } */

    .maps-link {
      text-decoration: none;
      color: $black;
      margin-top: -10px;

      .maps-link-container {
        position: relative;
        background-color: $white;
        border-radius: 12px;
        height: 60px;
        width: 100%;
        font-size: 14px;
        font-weight:500;
        line-height:23px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 25px;

        span {
          padding-left: 18px;
        }

        img {
          position: absolute;
          right: 20px;
          height: 34px;
        }
      }
    }
  }
    
  #right{
    flex:1;
    background-color: white;
    padding-top: 130px;
  
    overflow-y:auto;
    overflow-x:hidden;
  
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    @include breakpoint-portrait-850px {
      display: none;
    }
  
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.mapPage{
    #left{
      overflow-y:auto;
      overflow-x:hidden;
      padding-top: calc(100vh - 320px);

      &.cookie-offset{
        padding-top: calc(100vh - 400px);
      }

      @include breakpoint-portrait-850px {
        padding-top: 160px;

        &.cookie-offset{
          padding-top: 160px;
        }
      }

      .mobile-content {
        position: relative;
      }

      .text-image{

        @include breakpoint-portrait-850px {
          margin-bottom: 100px;
        }
        
        .text-content{
          font-size:22px;
        }
      }
    }
  }

  .faq-sub-title {
    font-size: 26px;
    
    @include breakpoint-portrait-850px {
      font-size: 20px;
    }
  }

  .faq-title {
    font-size: 34px;

    @include breakpoint-portrait-850px {
      font-size: 28px;
    }
  }

  .faq-text {

  }

  .faq-image {
    margin-left: 67px;

    @include breakpoint-portrait-850px {
      margin-left: 48px;
    }
  }
}

.no-padding-top {
  padding-top: 0px !important;
}

.small-padding-top {
  padding-top: 70px !important;
}

.fade-enter-active {
  animation: 'fade-in' 0.2s;
}
.fade-leave-active {
  animation: 'fade-in' 0.2s reverse;
}

.places-teaser-list {
  margin-left: 50px;

  h1 {
    text-align: left;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 30px;
    font-weight:500;
  }

  .row {
    div {
      padding: 0;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity:0;
  }
  50% {
    opacity:0.5;
  }
  100% {
    opacity:1;
  }
}

.cookie-banner {
    font-family: 'Roboto', sans-serif;
    background-color: #2d373c;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing:border-box;
    z-index: 999;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items:center;
    padding: 18px 56px;
    display: none;
}

.cookie-banner.show {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items:center;
}

.cookie-banner .cookie-banner-text {
    display: inline-block;
    color: #fff;
}

.cookie-banner .cookie-banner-buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
}

.cookie-banner .cookie-banner-policy {
    color: #fff;
    margin-left: 36px;
    word-break: initial;
}

.cookie-banner .cookie-banner-policy:hover {
    text-decoration: underline;
}

.cookie-banner .cookie-banner-policy,.cookie-banner .cookie-banner-text {
    padding: 10px 0;
    font-size: 16px;
    line-height: 24px;
}

.cookie-banner .cookie-banner-ok.button.wide {
    width: auto;
    height:fit-content;
    margin-left: 36px;
    margin-bottom: 0;
    background-color: $yellow;
    padding:12px 18px;
    border-radius:12px;
    cursor:pointer;
    text-align:center;
}

@media all and (max-width: 760px) {
  .cookie-banner {
    flex-wrap: wrap;
    padding: 18px 25px;

    .cookie-banner-buttons {
      flex-basis:100%;
      flex-wrap: wrap;

      .cookie-banner-ok.button.wide {
        margin-left: 0;
        margin-top: 20px;
        width:100%;
      }

      .cookie-banner-policy {
        margin-left:0;
      }
    }
  }
}