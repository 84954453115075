@import "@/styles/_vars.scss";












































.share-button {
    display: flex;
    align-items: center;
    user-select: none;

    span {
        font-size: 14px;
        line-height: 23px;
        letter-spacing: 0.7px;
        color: $black;
        text-transform: uppercase;
        margin-right: 8px;
        cursor: pointer;
        font-weight:500;
    }

    img {
        cursor: pointer;
    }

    .share-menu {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        width: 330px;
        height: 50px;
        padding: 20px 45px;
        margin: 0 30px;
        left: 0;
        top: 100px;
        box-shadow: 1px 5px 12px rgba(0, 0, 0, 0.29);
        border-radius: 12px;

        img {
            width: 50px;
        }

        &::before {
            content: '';
            position: absolute;
            top: -24px;
            left: 356px;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 18px 28px 18px;
            border-color: transparent transparent #FFFFFF transparent;
            transform: rotate(0deg);
        }
    }
}
