@import "@/styles/_vars.scss";
















































































































































.left-content-container {
  .text-image {         
    .text-content {         
      @include breakpoint-portrait-850px {
        line-height: 20px;

        h3 {
          font-size:28px;
          line-height:40px;
        }

        h4,
        .subtitle {
          font-size:20px;
          line-height: 26px;
        }
      }
    }
  }
}

.text-image {
  .text-content {        
    h3 {
      font-weight:500;
      font-size:34px;
      line-height:46px;
    } 
    
    h4,
    .subtitle {
      margin:0;
    }
  }                
}
