@import "@/styles/_vars.scss";














































































































.vjs-theme-city.vjs-theme-radioorte{
    border-radius: 12px 12px 0 0;
    font-family: 'Roboto', sans-serif;
}

.vjs-theme-city.vjs-theme-radioorte video{
    border-radius: 12px 12px 0 0;
}

.vjs-theme-city.vjs-theme-radioorte.vjs-paused .vjs-big-play-button 
{
    display: none;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-control-bar {
    display:flex;
    height:120px;
    bottom: -120px;
    padding-top:0;
    background-image:none;
    background:#fff;
    border-radius: 0 0 12px 12px;
    flex-wrap:wrap;
}

.vjs-theme-city.vjs-theme-radioorte.vjs-fullscreen .vjs-control-bar {
    bottom: 0;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-progress-control {
    top:30px;
    left:20px;
    width:calc(100% - 40px);
}

.vjs-theme-city.vjs-theme-radioorte .vjs-load-progress {
    background: #C4C4C4;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-progress-holder {
    height: 5px;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-slider {
    border-radius: 5px;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-play-progress {
    background-color: #9F1B0A;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-progress-holder .vjs-play-progress, .vjs-theme-city.vjs-theme-radioorte .vjs-progress-holder .vjs-load-progress, .vjs-theme-city.vjs-theme-radioorte .vjs-progress-holder .vjs-load-progress div{
    border-radius: 5px;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-progress-control .vjs-mouse-display {
    background: #9F1B0A;
    height: 14px;
    width: 14px;
    top: -5px;
    border-radius: 50%;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-skip-backward-30,
.vjs-theme-city.vjs-theme-radioorte .vjs-skip-forward-30{
    width: calc(50% - 29px);
    background: #EFD234;
    height: 44px;
    top: -21.5px;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-skip-backward-30{
    order: 1;
    border-radius: 20px 0 0 20px;    
    left: 20px;
    text-align:left;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-skip-backward-30 .vjs-icon-placeholder:before{
    content: "";
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='34' viewBox='0 0 35 34'%3E%3Cg id='_' data-name='&lt;' transform='translate(-0.037 -0.407)'%3E%3Cellipse id='Ellipse_1' data-name='Ellipse 1' cx='17.5' cy='17' rx='17.5' ry='17' transform='translate(0.037 0.407)' fill='%23fff'/%3E%3Cg id='Back' transform='translate(5.016 5.394)'%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='24.025' height='24.025' transform='translate(0 0)' fill='none'/%3E%3Cg id='Group_2' data-name='Group 2' transform='translate(4.004 2.002)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M6.038,2A1.972,1.972,0,0,1,8,4v6.23l8.672-7.4a2.027,2.027,0,0,1,3.34,1.52V19.672a2.027,2.027,0,0,1-3.34,1.52L8,13.789v6.23a1.972,1.972,0,0,1-1.966,2,2.034,2.034,0,0,1-2.038-2V4A2.034,2.034,0,0,1,6.038,2Z' transform='translate(-4 -2)' fill='%239f1b0a'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat center left 6px;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-skip-forward-30 .vjs-icon-placeholder:before{
    content: "";
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='34' viewBox='0 0 35 34'%3E%3Cg id='_' data-name='&gt;' transform='translate(-23.471 34.407) rotate(180)'%3E%3Cellipse id='Ellipse_1' data-name='Ellipse 1' cx='17.5' cy='17' rx='17.5' ry='17' transform='translate(-58.471 0.407)' fill='%23fff'/%3E%3Cg id='Back' transform='translate(-52.984 5.394)'%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='24.025' height='24.025' transform='translate(0 0)' fill='none'/%3E%3Cg id='Group_2' data-name='Group 2' transform='translate(4.004 2.002)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M2.038,0A1.972,1.972,0,0,1,4,2v6.23L12.676.83a2.027,2.027,0,0,1,3.34,1.52V17.672a2.027,2.027,0,0,1-3.34,1.52L4,11.789v6.23a1.972,1.972,0,0,1-1.966,2,2.034,2.034,0,0,1-2.038-2V2A2.034,2.034,0,0,1,2.038,0Z' fill='%239f1b0a'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center right 6px;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-skip-backward-30 .vjs-icon-placeholder:after,
.vjs-theme-city.vjs-theme-radioorte .vjs-skip-forward-30 .vjs-icon-placeholder:after {  
    display:inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-skip-backward-30 .vjs-icon-placeholder:after{
    content: "-30s"; 
    margin-left: 46px;  
}

.vjs-theme-city.vjs-theme-radioorte .vjs-skip-forward-30 .vjs-icon-placeholder:after{
    content: "+30s"; 
    margin-right: 46px;  
}

.vjs-theme-city.vjs-theme-radioorte .vjs-skip-forward-30{
    order:3;
    border-radius: 0 20px 20px 0;
    right: 20px;
    text-align:right;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-play-control{
    top:-28px;
    font-size:2em;
    height:57px;
    width:57px;
    flex:none;
    order: 3;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-play-control.vjs-button>.vjs-icon-placeholder:before {
    line-height: 57px;
    z-index:2;
    background: #9F1B0A;
    height:100%;
    width:100%;
    border-radius:50%;
    font-size: 2.2em;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-play-control.vjs-button>.vjs-icon-placeholder:after {
    content: "";
    background: #EFD234;
    height: 44px;
    width:127px;
    position:relative;
    left: -35px;
    display: inline-block;
    z-index:1;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-time-control{
    color: #000000;
    font-size: 12px;  
    font-weight:500; 
    height:20px;
    width:50%;
    min-width:0;
    padding:0;
    line-height:1.5;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-current-time{
    display:block;
    order: 4;
    padding-left:20px;
    text-align:left;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-remaining-time{
    order: 5;
    flex:none;
    padding-right:20px;
    text-align:right;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-volume-panel{
    padding-left:20px;
    padding-top:10px;
    height:40px;
    order:6;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-volume-level:before{
    border-bottom:1.75em solid #000;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-fullscreen-control{
    order:7;
    text-align:right;    
    height:40px;
    padding-right:15px;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-fullscreen-control .vjs-icon-placeholder:before{
    text-align:right;
    font-size:3em;
    position:initial;
    line-height:1;
}


.vjs-theme-city.vjs-theme-radioorte .vjs-icon-fullscreen-enter:before, 
.vjs-theme-city.vjs-theme-radioorte .vjs-fullscreen-control .vjs-icon-placeholder:before{
    color: black;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-picture-in-picture-control{
    display:none;
}

.vjs-theme-city.vjs-theme-radioorte .vjs-poster img{
    border-radius:12px 12px 0 0;
}

.vjs-theme-city.vjs-theme-radioorte.video-js .vjs-progress-control .vjs-mouse-display{
    z-index:3;
}
