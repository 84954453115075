@import "@/styles/_vars.scss";
























.footer {
    background-color: $dark-grey;
    padding-left: 58px;
    padding-top: 40px;

    @include breakpoint-portrait-850px {
        padding-left: 50px;
    }

    ul {
        margin-bottom: 65px;
        padding-left: 0;
        list-style: none;
        
        li {
            a {
                text-decoration: none;
                color: $white;
                font-size: 18px;
                line-height: 28px;
            }
        }
    }

    .logo {
        width: calc(125px);
        margin-bottom: 50px;
        height: auto;

        @include breakpoint-portrait-850px {
            margin-bottom: 85px;
        }
    }
}
