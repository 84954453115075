@import "@/styles/_vars.scss";




































































































































































































































































































































































































































































































































.highlightedmarker {
  pointer-events: none;
}
.marker-wrapper{
  display:flex;
  justify-content: center;
  align-items: center;
  height:45px;
  width:45px;
  pointer-events: none;

  &.overview{
    height:85px;
    width:85px;
  }

  &.offset {
    width: 90px;

    .marker{
      margin: 0 -2px;
    }
  }

  .marker{
    height:25px;
    width:25px;
    border-radius:50%;
    background-color: $red;
    transition:all 0.2s linear;

    a{
      display:block;
      width:100%;
      height:100%;
      position:relative;
      z-index:999;
      pointer-events: all;

      @include breakpoint-portrait-850px {
        pointer-events: none;
      }
    }

    span {     
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      height: 85px;
      width: 85px;
      justify-content: center;
      align-items: center;
      color: white;
      font-size:16px;
    }

    &.overview{
      height:75px;
      width:75px;
    }

    &.active,
    &.active:not(.highlighted) + .marker{
      height:35px;
      width:35px;

      &.overview{
        height:85px;
        width:85px;
      }
    }

    &.disabled{
      background-color:$dark-grey;     
    }

    &.offset {
      margin-left: -45px;
      pointer-events: all;
    }
  }
} 
