@import "@/styles/_vars.scss";



























































































.search-bar-container {
    position: relative;
    box-sizing: border-box;
    margin-bottom:40px;
    margin-top:70px;

    @include breakpoint-portrait-850px {
        margin-left: -40px;
        margin-right: -40px;
        margin-top:0;
        margin-bottom:40px;
    }

    .search-bar {
        width: 100%;
        color: #000000;
        border-radius: 15px;
        border: 0;
        height: 92px;
        font-size: 14px;
        line-height: 23px;
        text-align: left;
        letter-spacing: 0.7px;
        padding-left: 20px;
        padding-right: 60px;
        box-sizing: border-box;
        
        @include breakpoint-portrait-850px {
            padding-left: 60px;
        }

        &:focus-visible, &:focus, &:active {
            border: 0;
            outline: none;
        }

        &::placeholder {
            text-transform: uppercase;
            color: $black;
            letter-spacing: 0.7px;
            font-family: 'Roboto', sans-serif;
            font-weight:500;               
        }
    }

    .search-icon {
        width: 32px;
        height: 39px;
        position: absolute;
        top: 30px;
        right: 25px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31.693' height='39.057' viewBox='0 0 31.693 39.057'%3E%3Cg id='Glass' transform='translate(-44 -121)'%3E%3Cg id='Glass-2' data-name='Glass' transform='translate(44 121)' fill='none'%3E%3Cpath d='M14.3,0A14.3,14.3,0,1,1,0,14.3,14.3,14.3,0,0,1,14.3,0Z' stroke='none'/%3E%3Cpath d='M 14.29733085632324 3 C 11.27970123291016 3 8.442691802978516 4.175130844116211 6.308910369873047 6.308910369873047 C 4.175130844116211 8.442691802978516 3 11.27970123291016 3 14.29733085632324 C 3 17.31496047973633 4.175130844116211 20.15196990966797 6.308910369873047 22.28575134277344 C 8.442691802978516 24.41953086853027 11.27970123291016 25.59466171264648 14.29733085632324 25.59466171264648 C 17.31496047973633 25.59466171264648 20.15196990966797 24.41953086853027 22.28575134277344 22.28575134277344 C 24.41953086853027 20.15196990966797 25.59466171264648 17.31496047973633 25.59466171264648 14.29733085632324 C 25.59466171264648 11.27970123291016 24.41953086853027 8.442691802978516 22.28575134277344 6.308910369873047 C 20.15196990966797 4.175130844116211 17.31496047973633 3 14.29733085632324 3 M 14.29733085632324 0 C 22.19353103637695 0 28.59466171264648 6.401130676269531 28.59466171264648 14.29733085632324 C 28.59466171264648 22.19353103637695 22.19353103637695 28.59466171264648 14.29733085632324 28.59466171264648 C 6.401130676269531 28.59466171264648 0 22.19353103637695 0 14.29733085632324 C 0 6.401130676269531 6.401130676269531 0 14.29733085632324 0 Z' stroke='none' fill='%23efd234'/%3E%3C/g%3E%3Crect id='Rectangle_1494' data-name='Rectangle 1494' width='4.085' height='16.34' rx='2.042' transform='matrix(0.819, -0.574, 0.574, 0.819, 62.975, 146.672)' fill='%23efd234'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat:no-repeat;
        cursor:pointer;

        @include breakpoint-portrait-850px {
            right: 60px;
        }
    }

    ul {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 25px 20px;
        list-style: none;
        margin: 0 auto auto;
        width: 83%;
        padding: 0;
        overflow: hidden;

        li {
            color: $dark-grey;
            font-size: 18px;
            text-align: left;
            padding-left: 20px;
            padding-right: 20px;
            margin: 20px 0;
            cursor:pointer;

            &.active {
                background-color: $black;
                color: $white;
            }
        }
    }
}
