@import "@/styles/_vars.scss";


















































































































.places-title{
  font-size:14px;
  text-transform: uppercase;
  font-weight:500;
  margin-left:16px;
  margin-bottom:10px;

  @include breakpoint-portrait-850px {
    display:none;
  }
}

.places-title-mobile{
  display:none;
  font-size:14px;
  text-transform: uppercase;
  font-weight:500;
  margin-left:16px;
  margin-bottom:10px;

  @include breakpoint-portrait-850px {
    display:block;
  }
}

.places-list{
  display:flex;
  flex-direction:column;

  @include breakpoint-portrait-850px {
    padding-left: 0;
    padding-right: 0;
    margin-bottom:45px;
  }
}
